import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withFocus } from '@accedo/vdkweb-navigation';
import categoryItemTheme from './elevateCategoryItem.scss';

const TOP_POSITION = 9;
const MARGIN = 10;

const ElevateCategoryItem = ({
  data,
  nav,
  width: widthProp,
  height: heightProp,
  onClick
}) => {
  const [width] = useState(widthProp);
  const [height] = useState(heightProp);

  const wrapperClassName = `${categoryItemTheme.wrapper} ${
    nav.isFocused ? categoryItemTheme.focused : ''
  }`;

  return (
    <div
      className={wrapperClassName}
      style={{ width, height }}
      onMouseOver={nav.onMouseOver}
      onMouseOut={nav.onMouseOut}
      onClick={onClick}
      role="button"
    >
      <div className={categoryItemTheme.title}>{data.title}</div>
    </div>
  );
};

ElevateCategoryItem.propTypes = {
  data: PropTypes.object,
  height: PropTypes.any,
  width: PropTypes.any,
  nav: PropTypes.object,
  onClick: PropTypes.func
};

export default withFocus(ElevateCategoryItem);
