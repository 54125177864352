import { useContext, useCallback, useEffect } from 'react';
import { MenuContext } from '#/context/MenuContext';

const useMenu = ({ menuVisible = true } = {}) => {
  const { show, hide, isVisible } = useContext(MenuContext);

  useEffect(() => {
    if (menuVisible) {
      show();
    } else {
      hide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showMenu = useCallback(() => {
    return show();
  }, [show]);

  const hideMenu = useCallback(() => {
    return hide();
  }, [hide]);

  return { showMenu, hideMenu, isMenuVisible: isVisible };
};
export default useMenu;
