import React, { useState, createContext, useCallback } from 'react';
import PropTypes from 'prop-types';

/**
 *
 */
const ExtraClassesContext = createContext();
const ExtraClassesSetContext = createContext();

const ExtraStyleClassesProvider = ({ children }) => {
  const [extraClasses, setExtraClasses] = useState([]);

  const updateExtraClass = useCallback(
    classNames => setExtraClasses(classNames),
    []
  );

  return (
    <ExtraClassesContext.Provider value={extraClasses}>
      <ExtraClassesSetContext.Provider value={updateExtraClass}>
        {children}
      </ExtraClassesSetContext.Provider>
    </ExtraClassesContext.Provider>
  );
};

ExtraStyleClassesProvider.propTypes = {
  children: PropTypes.node
};

export {
  ExtraClassesContext,
  ExtraClassesSetContext,
  ExtraStyleClassesProvider
};
