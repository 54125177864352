import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FocusDiv } from '@accedo/vdkweb-tv-ui';

import AssetItem from '#/components/Item/AssetItem/AssetItem';
import { ITEM_SIZE } from '#/components/Item/itemHelper';
import ContentGrid from '#/components/ContentGrid/DynamicSizeContentGrid';
import getResolution from '#/utils/getResolution';
import { actionClickItem } from '#/services/analytics';
import useI18n from '#/hooks/useI18n';
import { R720p } from '#/theme/variables/breakpoints';
import { getGridSettingMap } from './utils';

import shelfTheme from './gridShelf.scss';

const GRID_ALIGNMENT = {
  VERTICAL: 'grid-align-vertical',
  HORIZONTAL: 'grid-align-horizontal'
};

const getNavIds = navId => ({
  CONTAINER_ID: navId,
  SPOTLIGHT_ITEM_ID: `${navId}-SPOTLIGHT_ITEM`,
  GRID_ID: `${navId}-GRID`
});

const checkEnableSpotlight = template => template.includes('asym');

const Separator = width => {
  return <div style={{ display: 'inline-block', width }} />;
};

const GridShelf = ({
  onClick,
  keyProperty = 'id',
  config,
  itemType,
  enableSpotlight,
  ds,
  items,
  total,
  nav,
  DisplayObject = AssetItem,
  theme = shelfTheme,
  gridAlignment = GRID_ALIGNMENT.HORIZONTAL,
  assetItemMargin: assetItemMarginProp
}) => {
  const { width: screenWidht } = getResolution();
  const ASSET_ITEM_MARGIN = screenWidht > R720p ? 10 : 7;
  const assetItemMargin = assetItemMarginProp || ASSET_ITEM_MARGIN;
  const { currentLocale = {} } = useI18n();
  const { dir } = currentLocale;
  const { id: shelfId, template, itemOptions } = config;

  const navIds = getNavIds(shelfId);
  const { CONTAINER_ID, SPOTLIGHT_ITEM_ID, GRID_ID } = navIds;

  const itemSizeDefault = ITEM_SIZE[itemType];

  const [focusAt, setFocusAt] = useState(GRID_ID);
  const [divContainerRef, setDivContainerRef] = useState();

  // We would check if `enableSpotlight is explicitly set.
  // Otherwise we will check template config to evaluatate
  // if we should use spotlight.
  enableSpotlight =
    enableSpotlight !== undefined
      ? enableSpotlight
      : checkEnableSpotlight(template);

  useEffect(() => {
    if (enableSpotlight) {
      setFocusAt(SPOTLIGHT_ITEM_ID);
    }
  }, [enableSpotlight, SPOTLIGHT_ITEM_ID, setFocusAt, items, ds, total]);

  const itemSize = { ...itemSizeDefault, ...itemOptions };

  const getShouldFocusSpotlight = () => {
    const { width } = getResolution();

    return (
      (!!divContainerRef &&
        divContainerRef.offsetWidth &&
        divContainerRef.offsetWidth < width) ||
      focusAt === navIds.SPOTLIGHT_ITEM_ID
    );
  };

  const getContainerLeft = () => {
    return !enableSpotlight || getShouldFocusSpotlight() ? 0 : -itemSize.width;
  };

  const getComponentNav = navId => {
    const compNavs = {
      [CONTAINER_ID]: {
        ...nav,
        id: CONTAINER_ID,
        forwardFocus: focusAt,
        useLastFocus: true
      },
      [SPOTLIGHT_ITEM_ID]: {
        id: SPOTLIGHT_ITEM_ID,
        parent: CONTAINER_ID,
        nextright: GRID_ID,
        internal: {
          nextright: () => {
            setFocusAt(GRID_ID);
          }
        }
      },
      [GRID_ID]: {
        id: GRID_ID,
        parent: CONTAINER_ID,
        nextleft: enableSpotlight ? SPOTLIGHT_ITEM_ID : '',
        internal: {
          nextleft: () => {
            enableSpotlight && setFocusAt(SPOTLIGHT_ITEM_ID);
          }
        },
        useLastFocus: true
      }
    };

    return compNavs[navId];
  };

  const getContainer = children => {
    return (
      <div
        className={theme.innerContainer}
        style={{
          left: getContainerLeft()
        }}
        ref={ref => {
          setDivContainerRef(ref);
        }}
      >
        {children}
      </div>
    );
  };

  const getAssetItem = () => {
    const { width, height } = itemSize;

    return (
      <AssetItem
        nav={getComponentNav(SPOTLIGHT_ITEM_ID)}
        data={items[0]}
        width={width * 2 + ASSET_ITEM_MARGIN}
        height={height * 2 + ASSET_ITEM_MARGIN * 2}
        onClick={() => onClick(items[0])}
      />
    );
  };

  const getFocusGrid = () => {
    const gridConfig = getGridSettingMap(itemType, enableSpotlight);

    const { width: itemWidth, height: itemHeight } = itemSize;
    const {
      headPadding,
      tailPadding,
      crossOffset,
      onChange,
      useInternalArrows,
      gridRef: ref,
      initialState,
      spacing,
      width = 1920,
      height = 1080
    } = config;

    return (
      <ContentGrid
        theme={theme}
        ref={ref}
        onChange={onChange}
        useInternalArrows={useInternalArrows}
        nav={getComponentNav(GRID_ID)}
        initialState={{
          position: 0,
          id: items?.[0]?.[keyProperty],
          ...initialState
        }}
        dir={dir}
        keyProperty={keyProperty}
        horizontal={gridAlignment === GRID_ALIGNMENT.HORIZONTAL}
        vertical={gridAlignment === GRID_ALIGNMENT.VERTICAL}
        width={
          gridAlignment === GRID_ALIGNMENT.HORIZONTAL
            ? width
            : Math.min(
                width,
                (itemWidth + assetItemMargin * 2) * gridConfig.col
              )
        }
        height={
          gridAlignment === GRID_ALIGNMENT.VERTICAL
            ? height
            : Math.min(
                height,
                (itemHeight + assetItemMargin * 2) * gridConfig.row
              )
        }
        itemWidth={itemWidth}
        itemHeight={itemHeight}
        ds={ds}
        data={items}
        buffer={3}
        spacing={spacing || assetItemMargin * 2}
        headPadding={headPadding || 0}
        tailPadding={tailPadding || 0}
        crossOffset={crossOffset || 0}
        onClick={({ data }) => {
          actionClickItem(data, config.displayText);
          return onClick(data);
        }}
        DisplayComponent={DisplayObject}
        displayComponentProps={{
          type: itemType,
          style: { itemWidth, itemHeight }
        }}
      />
    );
  };

  return (
    <FocusDiv
      className={theme.outerContainer}
      nav={getComponentNav(CONTAINER_ID)}
    >
      {enableSpotlight
        ? getContainer(
            <>
              {getAssetItem()}
              <Separator width={assetItemMargin * 2} />
              {getFocusGrid()}
            </>
          )
        : getFocusGrid()}
    </FocusDiv>
  );
};

GridShelf.propTypes = {
  onClick: PropTypes.func,
  items: PropTypes.array,
  ds: PropTypes.shape({
    hasData: PropTypes.func.isRequired,
    isPaginationAllowed: PropTypes.func.isRequired,
    getTotalNumber: PropTypes.func.isRequired,
    getData: PropTypes.func.isRequired
  }),
  config: PropTypes.shape({
    id: PropTypes.string.isRequired,
    displayText: PropTypes.string,
    template: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    itemOptions: PropTypes.object,
    prefix: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    headPadding: PropTypes.number,
    tailPadding: PropTypes.number,
    crossOffset: PropTypes.number,
    onChange: PropTypes.func,
    useInternalArrows: PropTypes.bool,
    gridRef: PropTypes.object,
    initialState: PropTypes.object,
    spacing: PropTypes.number,
    width: PropTypes.number,
    height: PropTypes.number,
    dir: PropTypes.string
  }).isRequired,
  keyProperty: PropTypes.string,
  nav: PropTypes.object,
  itemType: PropTypes.string,
  theme: PropTypes.object,
  total: PropTypes.number,
  enableSpotlight: PropTypes.bool,
  DisplayObject: PropTypes.func,
  gridAlignment: PropTypes.string,
  assetItemMargin: PropTypes.number
};

GridShelf.GRID_ALIGNMENT = GRID_ALIGNMENT;

export default GridShelf;
