const getImageByType = (images: any[], type = 'poster') => {
  if (!images?.length) {
    return null;
  }

  let imageFound = images.find(img => img.type === type);

  if (!imageFound) {
    const [imageFallback] = images;
    imageFound = imageFallback;
  }
  const imageUrl = imageFound.url || imageFound.fileUrl;

  return imageUrl;
};

export default getImageByType;
