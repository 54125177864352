import React from 'react';
import PropTypes from 'prop-types';

import defaultSrc from '#/static/images/elevate_color_negative.svg';

import styles from './logo.scss';

const Logo = ({ src = defaultSrc, className: propClassName }) => {
  const className = styles.logo;

  return (
    <img src={src} className={`${className} ${propClassName}`} alt="Logo" />
  );
};

Logo.propTypes = {
  className: PropTypes.string,
  src: PropTypes.string
};

export default React.memo(Logo);
