import React from 'react';
import PropTypes from 'prop-types';

import ContentGrid from '#/components/ContentGrid/DynamicSizeContentGrid';
import ChannelBannerItem from '#/components/Item/ChannelBannerlItem/ChannelBannerItem';

import { actionClickItem } from '#/services/analytics';
import useI18n from '#/hooks/useI18n';

import channelBannerTheme from './channelBanner.scss';

const itemWidth = 1120;
const itemHeight = 364;

const ChannelBannerShelf = ({ onClick, items, config = {}, nav = {}, ds }) => {
  const { currentLocale = {} } = useI18n();
  const { dir } = currentLocale;
  return (
    <ContentGrid
      data={items}
      ds={ds}
      dir={dir}
      keyProperty="id"
      itemWidth={itemWidth}
      itemHeight={itemHeight}
      horizontal
      headPadding={0}
      tailPadding={0}
      spacing={0}
      height={itemHeight}
      width={1920}
      loop
      theme={channelBannerTheme}
      onClick={source => {
        actionClickItem(source.data, config.displayText);
        onClick(source.data);
      }}
      DisplayComponent={ChannelBannerItem}
      displayComponentProps={{ height: itemHeight }}
      nav={{
        ...nav,
        id: config.id || nav.id
      }}
      initialState={{
        id: items?.[0]?.id,
        position: 0
      }}
    />
  );
};

ChannelBannerShelf.propTypes = {
  onClick: PropTypes.func,
  ds: PropTypes.object.isRequired,
  items: PropTypes.array,
  config: PropTypes.object,
  nav: PropTypes.object
};

export default ChannelBannerShelf;
