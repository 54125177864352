/* eslint-disable eslint-comments/disable-enable-pair */

import React, { useState, useCallback, useMemo, createContext } from 'react';
import PropTypes from 'prop-types';

export const MenuContext = createContext({
  isVisible: true,
  show: () => {},
  hide: () => {}
});

const MenuProvider = ({ children }) => {
  const [isVisible, setIsVisible] = useState(true);

  const show = useCallback(() => setIsVisible(true), []);

  const hide = useCallback(() => setIsVisible(false), []);

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <MenuContext.Provider value={{ isVisible, show, hide }}>
      {children}
    </MenuContext.Provider>
  );
};

MenuProvider.propTypes = {
  children: PropTypes.node
};

export default MenuProvider;
