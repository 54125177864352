import LiveProgram from '#/interfaces/live/LiveProgram';

const LiveModel = (rawLiveProgram: any): LiveProgram => {
  const {
    id,
    images,
    type,
    channelId,
    endTime,
    mediaId,
    startTime,
    title,
    videoUrl
  } = rawLiveProgram;

  const getId = (): string => {
    return id;
  };
  const getImages = (): [] => {
    return images;
  };
  const getType = (): string => {
    return type;
  };
  const getChannelId = (): string => {
    return channelId;
  };
  const getEndTime = (): number => {
    return endTime;
  };
  const getMediaId = (): string => {
    return mediaId;
  };
  const getStartTime = (): number => {
    return startTime;
  };
  const getTitle = (): string => {
    return title;
  };
  const getVideoUrl = (): string => {
    return videoUrl;
  };

  return {
    id: getId(),
    images: getImages(),
    type: getType(),
    channelId: getChannelId(),
    endTime: getEndTime(),
    mediaId: getMediaId(),
    startTime: getStartTime(),
    title: getTitle(),
    videoUrl: getVideoUrl()
  };
};

class LiveModelBuilder<T extends LiveProgram> {
  liveProgram: T;

  constructor(liveProgram: T) {
    this.liveProgram = liveProgram;
  }

  /**
   * Return/create the final LiveProgram object.
   * @returns {LiveProgram} "this" - Current LiveModelBuilder instance.
   */
  create(): LiveProgram {
    const newLiveProgram: LiveProgram = LiveModel(this.liveProgram);
    return newLiveProgram;
  }
}

export default LiveModelBuilder;
